

 export  function getParam(data){
    let url = '';
    for(var k in data){
      let value = data[k] !==undefined ? data[k] : '';
      url += `&${k}=${encodeURIComponent(value)}`
    }
    return url ? url.substring(1) : ''
  }

  /**
   * 将url和参数拼接成完整地址
   * @param {string} url url地址
   * @param {Json} data json对象
   * @returns {string}
   */
  export   function getUrl(url, data){
    //看原始url地址中开头是否带?，然后拼接处理好的参数
    return url += (url.indexOf('?') < 0 ? '?' : '') + getParam(data)
  }

